<script setup>
	import {
		ref,
		computed,
		onMounted,
	} from 'vue';
	import ModalDialogWithCta from 'shared/components/modalDialogWithCta.vue';
	import { useStore } from 'vuex';

	const props = defineProps({
		trigger: {
			type: String,
			required: true,
		},
		closeLabel: {
			type: String,
			default: 'Close'
		},
	});

	const
		store = useStore(),
		isProductCardOnCampaignPage = computed(() => store.getters.getIsProductCardOnCampaignPage);

	const
		show = ref(false),
		errorMessage = ref(null),
		description = ref(null),
		modalData = ref({});

	onMounted(() => {
		document.addEventListener(props.trigger, (e) => {
			modalData.value = e.detail;
			errorMessage.value = modalData.value?.ErrorHeading;
			description.value = modalData.value?.ErrorMessage;
			if (errorMessage.value && description.value) {
				show.value = true;
			}
			else {
				show.value = false;
			}
		})
	});

	function toggle() {
		show.value = !show.value;
	}
</script>

<template>
	<div class="c-modal__mask" @click="toggle()" @kedown.enter="toggle()" @keydown.escape="toggle()" v-if="show">
		<div class="c-modal__content-wrapper" :tabindex="show ? 1 : -1" @click.stop>
			<h2>{{ errorMessage }}</h2>
			<div v-html="description"></div>
			<button class="e-button e-button--filled close-popup-button" @click="toggle()" @keydown.enter="toggle()">{{ closeLabel }}</button>
		</div>
	</div>
	<ModalDialogWithCta v-if="modalData.isModal && isProductCardOnCampaignPage" :modal-data="modalData"></ModalDialogWithCta>
</template>
