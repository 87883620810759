const eventNames = [];

function emitEvent(e) {
    if (e) {
        e.preventDefault();
    }
	const eventName = (typeof e.target.dataset?.event !== 'undefined') ? e.target.dataset?.event : e.target?.parentElement?.dataset?.event;
	const eventParameter = (typeof e.target?.dataset?.eventParameter !== 'undefined') ? e.target?.dataset?.eventParameter : e.target?.parentElement?.dataset?.eventParameter

	if(eventNames.includes(eventName)) {
		const event = new CustomEvent(eventName, {
			detail: eventParameter,
		});
		document.dispatchEvent(event);
	}
}

export default function initCustomEventEmitter(element) {
	eventNames.push(element.dataset.event);
    if (!eventNames.length) {
        return;
    }
	element.addEventListener('click', emitEvent);
}
