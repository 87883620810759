<template></template>

<script>
import Cookie from 'js-cookie';
import { cookieNames } from 'shared/settings/constants';
import { track_form_submission } from 'shared/services/tracking/formTrackingService';

export default {
    mounted() {
        let payload = Cookie.get(cookieNames.FORM_SUBMIT_STATUS);
        if (payload) {
			let event = JSON.parse(payload);
            track_form_submission(event.formsubmission);
            Cookie.remove(cookieNames.FORM_SUBMIT_STATUS)
        }
    }
}
</script>
