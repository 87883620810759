<template>
    <button class="e-button e-button--tiny -new c-sample-button" data-btn @click="addToBasket" :data-js-apiurl="'/api/quicksample/addproductsamples?language=' + culture" ref="button">
        <span>{{ btnLabel }}</span>
    </button>
</template>

<script>
import { sampleCatalogContent } from 'oneblock/assets/js/oneblock-widgets.mjs';
import { mapGetters } from 'vuex';

export default {
    props: {
        ids: {
            type: String,
            required: true,
        },
        btnLabel: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            sampleIds: this.ids.split(';'),
        };
    },
    methods: {
        async addToBasket() {
            sampleCatalogContent(null, this.$refs.button, this.ids);
        }
    },
    computed: {
        ...mapGetters({
            culture: 'getCulture',
        }),
    }
}
</script>
