export default function initHideBodyScrollBar(element) {
	if (element == null) {
		return;
	}
	const BREAKPOINT = 768;
	element.addEventListener('change', () => {
		if (element.checked && window.innerWidth < BREAKPOINT) {
			document.body.style.overflowY = 'hidden';
		}
		else {
			document.body.removeAttribute('style');
		}
	});
	//in case orientation was changed and we get desktop nav view
	window.addEventListener('resize', function () {
		if (window.innerWidth >= BREAKPOINT && document.body.getAttribute('style') != null) {
			document.body.removeAttribute('style');
		}
	});
}
