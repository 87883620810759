<template>
	<div
		 class="c-modal__mask"
		 ref="externalLinkPopup"
		 id="external-link-popup"
		 v-show="show"
		 @click="toggle(false)"
		 @kedown.enter="toggle(false)"
		 @keydown.escape="toggle(false)"
		 >
		<div class="c-modal__content-wrapper" :tabindex="show ? 1 : -1" @click.stop>
			<h2 v-html="popup?.HeadlineText"></h2>
			<div v-html="popup?.MessageText"></div>
			<div class="c-modal-buttonsbox">
				<button class="e-button e-button--filled small" @click="toggle(false)" @keydown.enter="toggle(false)">{{ popup?.StayOnThisPageButtonText }}</button>
				<a
				   class="clickable-link e-button e-button--filled small"
				   :href="externalLink"
				   :target="target"
				   @click="toggle(false)"
				   @keydown.enter="toggle(false)"
				   >
				{{ popup?.OkButtonText }}
				</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		trigger: {
			type: String,
			required: true,
		},
		externalLinkPopup: {
			type: Object
		}
	},
	data() {
		return {
			show: false,
			popup: this.externalLinkPopup?.BaseViewModel,
			externalLink: '',
			target: ''
		}
	},
	methods: {
		toggle(show) {
			this.show = show;
		},
		isInWhiteList(url) {
			const urlToCompare = new URL(url).hostname.replace('www.', '');
			return this.popup.DomainWhitelist.some(domain => domain.indexOf(urlToCompare) != -1);
		}
	},
	mounted() {
		document.addEventListener(this.trigger, (e) => {
			const target = e.target.activeElement.getAttribute('target')
			this.target = target || '_self';

			if (this.popup.DomainWhitelist && this.popup.DomainWhitelist.length && this.isInWhiteList(e.detail)) {
				window.location.assign(e.detail);
			}
			else {
				this.show = true;
				this.externalLink = e.detail;
			}
		})
	}
}
</script>
