(function initializeConsole() {
	if (!window.hasOwnProperty('appInsights') || !window.hasOwnProperty('console')) {
		// if appInsights doesn't exist we will not do anything
		return;
	}
	
	function buildAppInsightsMessage(err) {
		let errorMessage = typeof err === 'string' ? err : '';
		// if this is called by an exception in the code err will be an object with a message property
		if (err.hasOwnProperty('message')) {
			errorMessage += err.message;
		}
		// if err also has a stack property (which it will if it's triggered by an exception) report that true
		if (err.hasOwnProperty('stack')) {
			errorMessage += '\n\n' + err.stack;
		}
		// else see if accessing the stack trace is supported in the browser; if it is then use that
		else if (Error.hasOwnProperty('captureStackTrace')) {
			let stackTrace = {};
			Error.captureStackTrace(stackTrace, console.error);
			errorMessage += '\n\n' + stackTrace.stack;
		}
		errorMessage += '\n\nUser agent: ' + navigator.userAgent;
		
		return errorMessage
	}

	function logToAppInsights(name, message, url) {
		// if ApplicationInsights are enabled then track the error
		if (appInsights.hasOwnProperty('trackEvent') && typeof appInsights.trackEvent === 'function') {
			appInsights.trackEvent({
				name: name,
				properties: {
					url: url,
					errorMessage: message,
				},
			});
			appInsights.flush();
		}
	}

	// save a reference to the original console.error method in a closure
	const _error = console.error;
	console.error = (err) => {
		let errorMessage = buildAppInsightsMessage(err);
		logToAppInsights('MS_FE_GENERAL_ERROR', errorMessage, window.location.href);

		// call the original console.error method
		if (typeof _error === 'function') {
			_error(err);
		}
	}

	// save a reference to the original console.warn method in a closure
	const _warn = console.warn;
	console.warn = (warning) => {
		let errorMessage = buildAppInsightsMessage(warning);

		// check if this is a Vue warning. If not then call the original console.warn method and return
		if (errorMessage.indexOf('[Vue warn]') !== -1) {
			logToAppInsights('MS_FE_VUE_WARNING', errorMessage, window.location.href);
		}

		// call the original console.warn method
		if (typeof _warn === 'function') {
			_warn(warning);
		}
	}



})();
