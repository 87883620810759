import Events from "shared/settings/events";

let activators;
let menuHeight;
const SCROLL_PADDING = 24;

export function initStepByStep(root) {
	const blockId = root.dataset.param;
	activators = root.querySelectorAll('[data-step-by-step-activator]');
	activators.forEach(activator => {
		activator.activationTarget = activator.parentElement.querySelector('[data-step-by-step-content]');
		activator.addEventListener('click', onActivatorClicked);
		activator.activationTarget.addEventListener('transitionend', onTransitionEnd);
		activator.blockId = blockId;
	});

	document.addEventListener(Events.VIDEO_PLAYING, (event) => {
		activators.forEach(activator => {
			if (activator.activationTarget.getAttribute('aria-expanded') == 'true') {
				animateToClientHeight(activator.activationTarget);
			}
		})
	});

	const menu = document.querySelector('[data-main-navigation]');
	if (menu) {
		menuHeight = menu.clientHeight;
	}

	let observer = new IntersectionObserver(intersectionObserverCallback, {
		threshold: 0.6,
	});
	observer.observe(root);
}

export function intersectionObserverCallback (entries, observer) {
	entries.forEach(entry => {
		if (entry.isIntersecting) {
			entry.target.classList.add('-in-view');
			observer.disconnect();
		}
	});
};


function onActivatorClicked(event) {
	const target = event.target.tagName == 'BUTTON' ? event.target : event.target.closest('button');
	activators.forEach(activator => {
		if (activator.blockId != target.blockId) {
			// only manipulate activators and activation targets within the same block as the one in which the button was clicked
			return;
		}
		if (activator == target) {
			activateContent(activator.activationTarget, activator.dataset.videoId);
			activator.setAttribute('data-active', 'true');
		}
		else {
			deactivateContent(activator.activationTarget, activator.dataset.videoId);
			activator.setAttribute('data-active', 'false');
		}
	});
}

function onTransitionEnd(event) {
	if (event.target.getAttribute('aria-expanded') == 'true') {
		const clientRect = event.target.parentElement.getBoundingClientRect();
		if (clientRect.top - menuHeight - SCROLL_PADDING < 0) {
			window.scrollBy(0, clientRect.top - menuHeight - SCROLL_PADDING);
		}
	}
}

function activateContent(activationTarget, videoId) {
	activationTarget.setAttribute('aria-expanded', 'true');
	const event = new CustomEvent(Events.PLAY, {
		detail: {
			id: videoId,
		},
	});
	document.dispatchEvent(event);
	animateToClientHeight(activationTarget);
}

function deactivateContent(activationTarget, videoId) {
	activationTarget.setAttribute('aria-expanded', 'false');
	const event = new CustomEvent(Events.PAUSE, {
		detail: {
			id: videoId,
		},
	});
	document.dispatchEvent(event);
	animateToZeroHeight(activationTarget);
}

function animateToClientHeight(activationTarget) {
	const content = activationTarget.querySelector('[data-item-content-wrapper]');
	activationTarget.style.height = `${content.clientHeight}px`;
}

function animateToZeroHeight(activationTarget) {
	activationTarget.style.height = '0';
}
