import events from 'shared/settings/events';
import { isExternalDomain } from 'shared/services/urlTypeService';

function fireExternalLinksEvent(url) {
	let event = new CustomEvent(events.EXTERNAL_LINK_CLICK, { detail: url });
	document.dispatchEvent(event);
}

export function isExternalClickableLink(a) {
	return a.classList.contains('clickable-link');
}

export default function initExternalLinksEvent() {
	document.body.addEventListener('click', (e) => {
		if (!e.target.tagName || !e.target?.closest('a')) return;

		let a = e.target.closest('a');
		if (isExternalDomain(a.href)) {
			const externalLinkPopup = document.getElementById('external-link-popup');
			if (externalLinkPopup != null && !isExternalClickableLink(a)) {
				e.preventDefault();
				fireExternalLinksEvent(a.href);
			}
		}
	}, true)
}
