export default function initMagazineNavigation() {
	const windowWidth = window.innerWidth;
	const isStickyHeader = !document.querySelector('header').classList.contains('sticky-disabled');
	const isMagazineNavigation = !!document.querySelector('[data-magazine-navigation]');
	const isMagazineSidebar = !!document.querySelector('[data-magazine-sidebar]');
	const isMagazineAnchors = !!document.querySelectorAll('[data-magazine-anchor]').length;
	const headerHeight = document.querySelector('header').clientHeight || 105;
	const magazineNavigationHeight = document.querySelector('[data-magazine-navigation]').clientHeight;

	window.addEventListener('load', function () {
		setScrollBarWidth();
		setSidebarOffset();
		setNavigationOffset();
		setAnchorOffset();
	});

	window.addEventListener('scroll', function () {
		setScrollBarWidth();
	});

	window.addEventListener('resize', function () {
		setSidebarOffset();
		setNavigationOffset();
		setAnchorOffset();
	});

	const setScrollBarWidth = () => {
		let windowScroll = document.body.scrollTop || document.documentElement.scrollTop;
		let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
		let scrolled = (windowScroll / height) * 100 || 0;
		document.querySelector('#progress-bar-dynamic').style.width = scrolled + '%';
	};

	const setSidebarOffset = () => {
		if (isMagazineNavigation && isMagazineSidebar && isStickyHeader) {
			document.querySelectorAll('[data-magazine-sidebar]').forEach((element) => {
				element.setAttribute('style', `top:${magazineNavigationHeight + headerHeight}px;`);
			});
		} else if (isMagazineNavigation && isMagazineSidebar && !isStickyHeader) {
			document.querySelectorAll('[data-magazine-sidebar]').forEach((element) => {
				element.setAttribute('style', `top:${magazineNavigationHeight}px;`);
			});
		}
	};

	const setNavigationOffset = () => {
		if (isMagazineNavigation && isStickyHeader) {
			document.querySelector('[data-magazine-navigation]').setAttribute('style', `top:${headerHeight - 2}px;`);
		} else if (isMagazineNavigation && !isStickyHeader) {
			document.querySelector('[data-magazine-navigation]').setAttribute('style', `top:-1px;`);
		}
	};

	const setAnchorOffset = () => {
		if (isMagazineAnchors && isStickyHeader) {
			document.querySelectorAll('[data-magazine-anchor]').forEach((element) => {
				element.setAttribute('style', `scroll-margin-top:${windowWidth < 767 ? magazineNavigationHeight + headerHeight - 24 : magazineNavigationHeight + headerHeight - 64}px;`);
			});
		} else if (isMagazineAnchors && !isStickyHeader) {
			document.querySelectorAll('[data-magazine-anchor]').forEach((element) => {
				element.setAttribute('style', `scroll-margin-top:${windowWidth < 767 ? magazineNavigationHeight - 24 : magazineNavigationHeight - 64}px;`);
			});
		}
	};

	const observerOptions = {
		root: null,
		rootMargin: '0px',
		threshold: 0.5,
	};

	document.querySelectorAll('[data-observe-block]').forEach((element) => {
		if (element) {
			let observer = new IntersectionObserver(intersectionCallback, observerOptions);
			observer.observe(element);
		}
	});

	function intersectionCallback(entries, observer) {
		const buttons = document.querySelectorAll('[data-magazine-navigation-button]');

		entries.forEach(entry => {
			if (entry.isIntersecting) {
				buttons.forEach(function(button) {
					if (button.getAttribute('href') === `#${entry.target.id}`) {
						button.setAttribute('style', 'color:#00788A;');
					}
				});
			} else {
				buttons.forEach(function(button) {
					if (button.hasAttribute('style')) {
						button.removeAttribute('style');
					}
				});
			}
		});
	}
}
