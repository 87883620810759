import { throttle } from "lodash";

const THRESHOLD = 30;
const SCROLL_DIFFERENCE_THRESHOLD = 2;
const MEDIA_QUERY = '(min-width: 768px)';
const HEADER_CLASS_SELECTOR = 'header.c-nav';
const HEADER_FIXED_CLASS = 'header--fixed-stack';
const FIXED_NAV_CLASS = 'fixed-nav';

let headerElement = null;
let lastScrollPosition = 0;
let mediaQueryList = null;

// apply the `fixed-nav` class when the nav is in sticky mode
export const stickyObserverFunction = ([entry]) => entry.target.classList.toggle(FIXED_NAV_CLASS, entry.intersectionRatio < 1)

function updateHeaderOnScroll() {
    const scrollY = window.scrollY;

    const scrollDifference = scrollY - lastScrollPosition;

    if (scrollDifference > SCROLL_DIFFERENCE_THRESHOLD) {
        requestAnimationFrame(() => headerElement.classList.remove(HEADER_FIXED_CLASS));
    } else if (scrollDifference < -THRESHOLD) {
        requestAnimationFrame(() => headerElement.classList.add(HEADER_FIXED_CLASS));
    }

    lastScrollPosition = scrollY;
}

export const detectShowHideNavItems = () => {
    if (mediaQueryList.matches) {
        updateHeaderOnScroll();
    }
}

export const initStickyMenu = (element) => {
    if (!element) {
        console.error('initStickyMenu requires a valid element');
        return;
    }

    mediaQueryList = window.matchMedia(MEDIA_QUERY);
    headerElement = document.querySelector(HEADER_CLASS_SELECTOR);

    if (!headerElement) {
        console.error(`Header element not found with selector: ${HEADER_CLASS_SELECTOR}`);
        return;
    }

    window.addEventListener("scroll", throttle(detectShowHideNavItems, 100));

    const stickyObserver = new IntersectionObserver(
        stickyObserverFunction,
        { threshold: [1] }
    );

    stickyObserver.observe(headerElement);
};

export default initStickyMenu;
