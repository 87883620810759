<template>
	<div id="global-spinner-overlay" data-testid="spinner" v-if="showSpinner">
		<div class="spinner"></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: "global-spinner",
	computed: {
		...mapGetters({
			showSpinner: 'getGlobalShowSpinner',
		}),
	},
}

</script>
