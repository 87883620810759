<template></template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Events from 'shared/settings/events';
import { track_begin_checkout } from 'shared/services/tracking/basketTrackingService';

export default {
    props: {
        basketUrl: {
            type: String,
            required: true,
        }
    },
    computed: {
        ...mapGetters({
            culture: 'getCulture',
			basket: 'getBasket',
        })
    },
    methods: {
		...mapActions({
            fetchBasket: 'fetchBasket',
        }),
		...mapMutations({
            setBasketApiCulture: 'setBasketApiCulture',
        }),
        async onSamplesAdded(e) {
            if (e.detail.basketUrl) {
				await this.fetchBasket();

				track_begin_checkout(this.basket, { quick_link: e.detail.quickSample ? 'yes' : 'no' });

                window.location.assign(e.detail.basketUrl);
            }
        }
    },
    mounted() {
		this.setBasketApiCulture(this.culture);
        window.addEventListener(Events.SAMPLES_ADDED, this.onSamplesAdded)
    }
}
</script>
