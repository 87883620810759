import Cookie from 'js-cookie';

const COOKIE_NAME = 'scrollpos';

export function initPostbackScrollPosition() {
    const scrollPosStr = Cookie.get(COOKIE_NAME);
    let scrollPosObj = null;
    if (scrollPosStr) {
        try {
            scrollPosObj = JSON.parse(scrollPosStr);
        }
        catch (e) {}
    }

    // now remove the cookie as we don't need it again
    Cookie.remove(COOKIE_NAME);

    // and make sure that the event handler is attached so the cookie will be set on activation
    const activators = [...document.querySelectorAll('.js-maintain-scroll')];
    activators.forEach(a => {
        a.addEventListener('click', onClick);
    });

    if (!scrollPosObj || !scrollPosObj.pos || !scrollPosObj.key) {
        return;
    }
    
    const selector = `[data-scroll-key="${scrollPosObj.key}"].js-maintain-scroll`;
    const activator = document.querySelector(selector);
    if (!activator) {
        // there is no activator matching the one for which the scroll position was saved. That means we are on a different page and we should not restore the scroll position
        return;
    }
    
    // now scroll the page
    window.scrollTo(0, scrollPosObj.pos);
}

function onClick(e) {
    let pos = window.scrollY;
    if (e.target.dataset.scrollTarget) {
        const elm = document.getElementById(e.target.dataset.scrollTarget);
        if (elm) {
            pos += elm.getBoundingClientRect().top;
        }
    }
    const obj = {
        key: e.target.dataset.scrollKey,
        pos: pos
    };
    Cookie.set(COOKIE_NAME, JSON.stringify(obj));
}



