import initMultisiteBasket from 'shared/vanilla/multisitebasket.js';
import initModalDialog from 'shared/vanilla/modalDialog.js';
import initCustomEventEmitter from 'shared/vanilla/customEventEmitter.js';
import initExternalLinksClickEvent from 'shared/vanilla/externalLinksClickEvent';
import RemoveOutline from "shared/vanilla/removeOutline";
import initHideBodyScrollBar from 'shared/vanilla/initHideBodyScrollBar';
import initLinkOverride from 'shared/vanilla/linkOverride';
import initHideChat from 'shared/vanilla/initHideChat';
import initTabbingCarousel from 'shared/vanilla/tabbingCarousel';
import initDownloadPdfUrl from 'shared/vanilla/initDownloadPdfUrl';
import initMagazineNavigation from 'shared/vanilla/initMagazineNavigation';

import { initStepByStep } from './blocks/stepByStep';

const functions = {
    initMultisiteBasket,
    initModalDialog,
	initCustomEventEmitter,
	initExternalLinksClickEvent,
	initHideBodyScrollBar,
	initLinkOverride,
	initStepByStep,
	initTabbingCarousel,
	initDownloadPdfUrl,
	initMagazineNavigation,
}

export default function () {
    const initters = [...document.querySelectorAll('[data-fn]')].map((elm) => {
        return {
            name: elm.dataset.fn,
            element: elm
        };
    });

    initters.forEach((initter) => {
        if (functions[initter.name] && typeof functions[initter.name] === 'function') {
            functions[initter.name](initter.element);
        }
    });

	const links = [...document.querySelectorAll('a')];
	initExternalLinksClickEvent(links);

    new RemoveOutline();
	initHideChat();
}
