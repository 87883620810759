<template>
	<div ref="phoneContainer" class="c-form-n__field-container">
		<input type="text" :name="name" :value="realPhoneNumber" hidden />
		<PhoneMask 
			v-model="phoneNumber" 
			:value="phoneNumber"
			@input="inputHandler"
			lang="EN" 
			:showRegion="false" 
		/>
	</div>
</template>
<script>
	import PhoneMask from 'shared/components/PhoneMask.vue';
	export default {
		name: "phone-field",
		props: ['name', 'userPhoneNumber', 'internationalCode'],
		components: {
			PhoneMask
		},
		data() {
			return {
				 realPhoneNumber: '',
				 phoneNumber: ''
			};
		},
		created() {
			if (this.userPhoneNumber && this.userPhoneNumber != '')
				this.phoneNumber = this.userPhoneNumber;
			else if (this.internationalCode && this.internationalCode != '')
				this.phoneNumber = this.internationalCode;
		},
		mounted() {
			const fakePhoneInput = this.$refs.phoneContainer.querySelector('.phone-mask-input');
			//to get the right length validation and the right format with slashes/ brackets and etc
			const inputValue = fakePhoneInput.value;
			if (inputValue != '')
				this.phoneNumber = inputValue;
		},
		computed: {
			maxLengthValidation() {
				return this.phoneNumber.replace(/[^\d_]/g, '').length;
			},
		},
		watch: {
			phoneNumber: {
				deep: true,
				handler: function (newVal) {
					const phoneNumberDigits = newVal.replace(/[^\d]/g, '');
					if (phoneNumberDigits.length === this.maxLengthValidation)
						this.realPhoneNumber = newVal;
					else
						this.realPhoneNumber = '';
				}
			},
		},
		methods: {
			inputHandler(val) {
				this.phoneNumber = val;
			}
		}
	};
</script>
