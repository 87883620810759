<script setup>
import SkeletonWrapper from 'shared/components/skeleton/skeletonWrapper.vue';
import SkeletonBox from 'shared/components/skeleton/skeletonBox.vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const areImagesLoaded = computed(() => store.getters['getGalleryItems']);
const imageGalleryElement = ref(document.getElementById('image-gallery'));
const isDisplayManufacturerLogo = computed(() => store.getters['getDisplayManufacturerLogo']);
const isGalleryMounted = computed(() => imageGalleryElement.value && areImagesLoaded.value.length || isDisplayManufacturerLogo.value);
const isSkeletonCreated = true;
</script>

<template>
	<skeleton-wrapper class="c-skeleton__image-gallery">
		<slot v-if="!isSkeletonCreated"></slot>
		<div v-if="!isGalleryMounted" v-for="element in 4" class="c-skeleton__image-gallery__wrapper"
			data-testid="skeleton-image">
			<skeleton-box width="100%" aspectRatio="1"></skeleton-box>
		</div>
	</skeleton-wrapper>
</template>

<style lang="scss">
@import "src/shared/styles/settings/settings";
@import "src/shared/styles/tools/tools";

.c-skeleton__image-gallery {
	width: 100%;
	height: 100%;
	display: flex;
	flex-wrap: nowrap;
	gap: 12px;
	max-width: 100vw;
	overflow-x: auto;

	@media (min-width: ds-breakpoint("md")) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 24px;
		overflow: hidden;
	}

	&__wrapper {
		flex: 1 0 100%;
    	flex-basis: 80%;
		max-width: 216px;
		width: 100%;

		@media (min-width: ds-breakpoint("md")) {
			max-width: 100%;
		}
	}

	.c-skeleton-box {
		border-radius: 8px;
	}
}
</style>
