import { createRouter, createWebHashHistory } from 'vue-router';

let router = {};
if (document.getElementById('hcp-profile-page')) {
	router = createRouter({
		history: createWebHashHistory(),
		routes: [
			{
				path: '/',
				name: 'HcpProfilePage',
				beforeEnter: (to, from, next) => {
					if (window.screen.availWidth > 900 && from.name == null) { //900px is breakpoint value from c-hcp-profile-page.scss $profilePageBreakpoint
						router.push({ name: 'Overview' })
					}
					next();
				}
			},
			{
				path: `/overview`,
				name: 'Overview', //name is the same as in vm its key
				component: () => import(/* webpackChunkName: "hcp-overview" */ 'shared/components/HcpProfilePage/Tabs/HcpOverview.vue')
			},
			{
				path: `/personal-information`,
				name: 'PersonalInformation', //name is the same as in vm its key
				component: () => import(/* webpackChunkName: "hcp-personal-information" */ 'shared/components/HcpProfilePage/Tabs/hcpPersonalInformation.vue')
			},
			{
				path: `/elearning-access`,
				name: 'AccountAccess',
				component: () => import(/* webpackChunkName: "hcp-profile-elearning-access" */ 'shared/components/HcpProfilePage/Tabs/hcpProfileElearningAccess.vue')
			},
			{
				path: `/certificates`,
				name: 'Certificates',
				component: () => import(/* webpackChunkName: "hcp-profile-certificates" */ 'shared/components/HcpProfilePage/Tabs/hcpProfileCertificates.vue')
			},
			{
				path: `/notifications`,
				name: 'Notifications',
				component: () => import(/* webpackChunkName: "hcp-profile-notifications" */ 'shared/components/HcpProfilePage/Tabs/hcpProfileNotifications.vue')
 			}
		],
		linkActiveClass: "active",
		linkExactActiveClass: "active",
		scrollBehavior() {
			return { left: 0, top: 0 }
		}
	});
}

export default router;

