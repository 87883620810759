function addStyleTag() {
	const css = '.embeddedServiceHelpButton, .embeddedServiceSidebar { display: none !important; }';

	const head = document.head || document.getElementsByTagName('head')[0];
	const style = document.createElement('style');

	head.appendChild(style);
	style.appendChild(document.createTextNode(css));
}

export default function initHideChat() {
	if (document.querySelector("[data-checkout-item]")) {
		addStyleTag();
	}
}
