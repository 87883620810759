let /** @type {HTMLHeadElement} header.c-nav */ header = null;
let /** @type {HTMLDivElement} .c-nav__section-main-navigation */ nav = null;
let /** @type {HTMLElement} .c-nav-menu */ navElem = null;
let /** @type {HTMLAnchorElement} :scope > .c-nav-item */ navItems = null;
let /** @type {HTMLAnchorElement} */ firstNavItem = null;
let /** @type {HTMLAnchorElement} */ lastNavItem = null;
let /** @type {HTMLDivElement} .c-nav-placeholder */ placeholder = null;
let /** @type {HTMLElement} #multi-level-menu */ multiLevelMenu = null;
let /** @type {HTMLElement} */ headerFirstFocusableEl = null;
let /** @type {HTMLElement} */ headerLastFocusableEl = null;
let /** @type {HTMLElement[]} */ allHeaderFocusableEls = null;

let resizeObserverInline = null;
let resizeObserverStacked = null;
let resizeObserverHeader = null;
let timeout = null;
let isTouch = false;

const getMediaQueryList = () => {
	const mediaQuery = '(min-width: 768px)';
	return window.matchMedia(mediaQuery);
}

/**
 * Listens for media query change (at 768px), and appropriately attaches/detaches desktop/mobile
 * event listeners
 * @listens var:{@link mediaQueryList}~e:change - media-query change at 768px
 * @triggered f:{@link attachObservers}
 */
export const attachResponsiveBehaviour = () => {
	const mediaQueryList = getMediaQueryList();

	if (mediaQueryList.matches) {
		attachDesktopEvents();
	} else {
		attachMobileEvents();
	}

	mediaQueryList.addEventListener('change', event => {
		if (event.matches) {
			attachDesktopEvents();
			detachMobileEvents();
		} else {
			attachMobileEvents();
			detachDesktopEvents();
		}
	});
}

/**
 * Adds/removes mobile events. Is triggered in appropriate remove/add mode when the mediaQueryList
 * emits 'change' event, which happens at 768px
 * @triggered var:${@link mediaQueryList}~e:change
 * @triggered f:{@link attachResponsiveBehaviour}
 */
export const attachMobileEvents = (attach = true) => {
	const buttons = document.querySelectorAll(".c-nav-item__button");

	buttons.forEach(button => {
		if (attach) {
			button.addEventListener("click", toggleAriaExpanded);
		} else {
			button.removeEventListener("click", toggleAriaExpanded);
		}
	});
};

export const detachMobileEvents = () => {
	attachMobileEvents(false);
};

/**
 * Adds/removes desktop events. Is triggered in appropriate remove/add mode when the mediaQueryList
 * emits 'change' event, which happens at 768px
 * @triggered var:{@link mediaQueryList}~e:change
 * @triggered f:{@link attachResponsiveBehaviour}
 */
export const attachDesktopEvents = (attach = true) => {
	document.documentElement.classList.remove("nav-open", "nav-search", "nav-search-popup");
	const subMenus = document.querySelectorAll(".c-nav-item--has-sub-menu > .c-nav__submenu-container");
	subMenus.forEach(subMenu => {
		// Set aria attribute for sub-menu
		const subMenuContainer = subMenu.parentNode;
		subMenu.setAttribute("aria-hidden", "true");
		const button = subMenuContainer.querySelector(".c-nav-item__button");
		button.setAttribute("aria-expanded", "false");

		if (attach) {
			subMenuContainer.addEventListener("focusin", openSubMenu);
			subMenuContainer.addEventListener("focusout", closeSubMenu);
			subMenuContainer.addEventListener("keydown", handleKeyEventsInSubMenu);
			subMenuContainer.addEventListener("mouseenter", removeFocusFromOtherNavSections);
			subMenuContainer.addEventListener("touchend", forceTabletFocus);
			document.addEventListener("touchend", closeOnTouchOutside);
		} else {
			subMenuContainer.removeEventListener("focusin", openSubMenu);
			subMenuContainer.removeEventListener("focusout", closeSubMenu);
			subMenuContainer.removeEventListener("keydown", handleKeyEventsInSubMenu);
			subMenuContainer.removeEventListener("mouseenter", removeFocusFromOtherNavSections);
			subMenuContainer.removeEventListener("touchend", forceTabletFocus);
			document.removeEventListener("touchend", closeOnTouchOutside);
		}
	});
};

export const detachDesktopEvents = () => {
	attachDesktopEvents(false);
};

/**
 * Removes the focus from other nav sections (eg. prevents from two submenus being open, when
 * one is focused with Tab, and the the other is hovered with mouse)
 * @triggered .c-nav-item__button~e:mouseenter
 */
export const removeFocusFromOtherNavSections = e => {
	if (!e.target.contains(document.activeElement)) {
		document.activeElement.blur();
	}
};

export const forceTabletFocus = e => {
	e.stopPropagation();
	isTouch = true;
	header.classList.add("--touch");
	if (e.target.classList.contains("c-nav-item__button")) {
		closeOpenSubMenu();
		e.target.focus();
	}
};

export const closeOnTouchOutside = e => {
	const openMenuButton = document.querySelector(".c-nav-item__button[aria-expanded='true']");
	const openSubMenuContainer = openMenuButton ? openMenuButton.closest(".c-nav-item--has-sub-menu") : null;
	if (openSubMenuContainer && !openSubMenuContainer.contains(e.target)) {
		closeSubMenuByElem(openSubMenuContainer);
	}
};

/**
 * Dynamically change aria-labels, which are also used by the CSS to show/hide sub-menu
 * @triggered f:attachMobileEvents -> button.c-nav-item__button::click
 */
export const toggleAriaExpanded = e => {
	const button = e.currentTarget;
	const submenu = button.nextElementSibling;
	if (button.getAttribute("aria-expanded") === "true") {
		button.setAttribute("aria-expanded", "false");
		submenu.setAttribute("aria-hidden", "true");
	} else {
		button.setAttribute("aria-expanded", "true");
		submenu.setAttribute("aria-hidden", "false");
	}
};

export const getInlineSize = (entry) => {
	const hasBorderBoxSize = typeof entry.borderBoxSize !== "undefined";
	if (hasBorderBoxSize) {
		return Array.isArray(entry.borderBoxSize) ? entry.borderBoxSize[0].inlineSize : entry.borderBoxSize.inlineSize;
	}
	return entry.contentRect.width;
}

export const getBlockSize = (entry) => {
	const hasBorderBoxSize = typeof entry.borderBoxSize !== "undefined";
	if (hasBorderBoxSize) {
		return Array.isArray(entry.borderBoxSize) ? entry.borderBoxSize[0].blockSize : entry.borderBoxSize.blockSize;
	}
	return entry.contentRect.height;
}

export const stackedObserver = (entries) => {
	if (header.classList.contains("header--nav-stacked")) {
		for (let entry of entries) {
			// Ensure we won't thrash between layout modes by checking overflow state here too
			const isOverflown = lastNavItem.offsetTop > firstNavItem.offsetTop + firstNavItem.clientHeight;

			const entryInlineSize = getInlineSize(entry);
			if (!isOverflown && Math.ceil(navElem.offsetWidth) < Math.ceil(entryInlineSize)) {
				resizeObserverStacked.unobserve(placeholder);
				setTimeout(() => {
					header.classList.remove("header--nav-stacked");
					resizeObserverInline.observe(nav, { box: "border-box" });
				}, 10);
			}
		}
	}
};

export const inlineObserver = (entries) => {
	if (!header.classList.contains("header--nav-stacked")) {
		entries.forEach(() => {
			const isOverflown = lastNavItem.offsetTop > firstNavItem.offsetTop + firstNavItem.clientHeight;
			if (isOverflown) {
					resizeObserverInline.unobserve(nav);
					setTimeout(() => {
						header.classList.add("header--nav-stacked");
						resizeObserverStacked.observe(placeholder, { box: "border-box" });
					}, 10);
				}
		});
	}
};

// Allow css to pick up the header height
export const headerObserver = (entries) => {
	const headerSize = getBlockSize(entries[0]);
	header.setAttribute("style", `--header-height: ${headerSize}px`);
};

/**
 * @triggered f:{@link initInlineStacking}
 */
export const attachObservers = () => {
	resizeObserverInline = new ResizeObserver(inlineObserver);
	resizeObserverStacked = new ResizeObserver(stackedObserver);
	resizeObserverHeader = new ResizeObserver(headerObserver);
	resizeObserverInline.observe(nav, { box: "border-box" });
	resizeObserverHeader.observe(header, { box: "border-box" });

	attachAriaAttributes();
	attachResponsiveBehaviour();
};

export const attachObserversForMultiLevel = () => {
	multiLevelMenu = document.getElementById("multi-level-menu");
	clearTimeout(timeout);
	if (multiLevelMenu.__vue__) {
		navItems = document.querySelectorAll(".c-multi-level-menu__subitem-primary");
		firstNavItem = navItems[0];
		lastNavItem = navItems[navItems.length - 1];
		attachObservers();
	} else {
		timeout = setTimeout(attachObserversForMultiLevel, 500);
	}
};

export const expandActiveMenuItems = () => {
	const menuContainer = document.querySelector(".c-nav-menu");
	const activeItem = menuContainer.querySelector('.-active');
	if (!activeItem) {
		return;
	}
	const button = activeItem.closest('.c-nav-item')?.querySelector('button');
	if (button) {
		openSubMenu({
			target: button,
			currentTarget: button.parentElement,
		});
	}
};

/**
 * Attaches menu observers, attaches mobile-toggle behavior
 * @triggered file:nav.js~f:initNavDomReady
 */
export const initInlineStacking = () => {
    header = document.querySelector("header.c-nav");
    nav = document.querySelector(".c-nav__section-main-navigation");
    navElem = nav.querySelector(".c-nav-menu");
	navItems = navElem.querySelectorAll(":scope > .c-nav-item");
	firstNavItem = navItems[0]?.classList.contains("visible-mobile-only") ? navItems[1] : navItems[0];
	lastNavItem = navItems[navItems.length - 1];
    placeholder = document.querySelector(".c-nav-placeholder");
	multiLevelMenu = document.getElementById("multi-level-menu");

	// Only attach behaviours if we have nav items
	if (firstNavItem && lastNavItem) {
		attachObservers();
	} else if (multiLevelMenu) {
		// Multi-level menu may not have mounted
		attachObserversForMultiLevel();
	}

	attachMobileToggleBehaviour();

	// if on mobile, expand the active top menu item
	const mediaQueryList = getMediaQueryList();
	if (!mediaQueryList.matches) {
		expandActiveMenuItems();
	}
}

/**
 * Opens the _sub-menu_ by setting appropriate aria-labels & focuses the first element inside
 * in such exists
 * @param {FocusEvent} e
 * @triggered div.c-nav-item~e:focusin
 */
export const openSubMenu = (e) => {
	const subMenuContainer = e.currentTarget;
	const subMenuButton = subMenuContainer.querySelector(".c-nav-item__button");
	const subMenu = subMenuContainer.querySelector(".c-nav__submenu-container");
	subMenuButton.setAttribute("aria-expanded", "true");
	subMenu.setAttribute("aria-hidden", "false");

	const subMenuItems = subMenu.querySelectorAll(".c-nav__submenu-item > a");
	const firstSubMenuItem = subMenuItems.length ? subMenuItems[0] : null;

	const targetIsButton = e.target === subMenuButton;

	if (targetIsButton && firstSubMenuItem) {
		firstSubMenuItem.focus();
	}
};

/**
 * Closes the _sub-menu_ if navigating to the other _sub-menu_
 * @param {FocusEvent} e
 * @triggered div.c-nav-item~e:focusout
 */
export const closeSubMenu = (e) => {
	const subMenuContainer = e.currentTarget;
	// Only close the menu if the element about to be focused is not inside the submenu
	if (!isTouch) {
		const relatedTargetIsInsideSubMenu =
			e.relatedTarget !== null ? subMenuContainer.contains(e.relatedTarget) : false;
		if (!relatedTargetIsInsideSubMenu || e.relatedTarget === null) {
			closeSubMenuByElem(subMenuContainer);
		}
	}
};

/**
 * Finds the opened _sub-menu_ and closes it
 */
export const closeOpenSubMenu = () => {
	const openMenuButton = document.querySelector(".c-nav-item__button[aria-expanded='true']");
	const openSubMenuContainer = openMenuButton ? openMenuButton.closest(".c-nav-item--has-sub-menu") : null;
	if (openSubMenuContainer) {
		closeSubMenuByElem(openSubMenuContainer);
	}
}

/**
 * Closes the _sub-menu_ contained within _subMenuContainer_ via setting the appropriate aria-labels.
 * The aria labels affect the CSS and thus the sub-menu closes
 * @param {HTMLDivElement} subMenuContainer `div.c-nav-item`, which contains the _button_ (`.c-nav-item__button`)
 * and the _sub-menu container_ with its elements (`.c-nav__submenu-container`)
 */
export const closeSubMenuByElem = (subMenuContainer) => {
	const subMenuButton = subMenuContainer.querySelector(".c-nav-item__button");
	const subMenu = subMenuContainer.querySelector(".c-nav__submenu-container");
	subMenuButton.setAttribute("aria-expanded", "false");
	subMenu.setAttribute("aria-hidden", "true");
}

/**
 * Finds first focusable element inside the _siblingElem_ (`div.c-nav-item`) and focuses it
 * @param {KeyboardEvent} e keyboard event coming from the {@link handleKeyEventsInSubMenu} function
 * @param {HTMLDivElement} siblingElem `div.c-nav-item`, which contains the _button_ (`.c-nav-item__button`)
 * and the _sub-menu container_ with its elements (`.c-nav__submenu-container`)
 */
export const navigateToSibling = (siblingElem, e) => {
	if (siblingElem) {
		e.preventDefault();
		const siblingFocalElem = siblingElem.querySelector(".c-nav-item__button, .c-nav-item__link");
		siblingFocalElem.focus();
		return true;
	}
	return false;
};

/**
 * Handles closing/opening the sub-menus when navigating with 'Tab' and 'arrow keys'
 * @param {KeyboardEvent} e
 * @triggered div.c-nav-item~e:keydown
 */
export const handleKeyEventsInSubMenu = (e) => {
	const subMenuContainer = e.currentTarget;
	const isTab = e.key === "Tab";
	const forwardTab = isTab && !e.shiftKey;
	const backwardTab = isTab && e.shiftKey;

	const isArrowDown = e.key === "ArrowDown";
	const isArrowUp = e.key === "ArrowUp";

	if (isArrowDown || isArrowUp) {
		const links = Array.from(subMenuContainer.querySelectorAll(".c-nav__submenu a"));
		const currentIndex = links.indexOf(e.target);

		if (isArrowDown) {
			e.preventDefault();
			links[(currentIndex + 1) % links.length].focus();
		}

		if (isArrowUp) {
			links[(currentIndex + links.length - 1) % links.length].focus();
		}
	}

	if (forwardTab) {
		navigateToSibling(subMenuContainer.nextElementSibling, e);
	}

	if (backwardTab) {
		const tabbed = navigateToSibling(subMenuContainer.previousElementSibling, e);
		if (!tabbed) {
			// Can't navigate back, must be at the start of the menu
			e.preventDefault();
			const logoLink = document.querySelector(".c-nav-logo__link");
			if (logoLink) {
				logoLink.focus();
			}
		}
	}
};

/**
 * Dynamically sets proper aria-labels values for mobile navigation hamburger button
 * @triggered f:attachMobileToggleBehaviour -> button.c-nav-mobile~e:click
 */
const adjustHamburgerButtonAriaLabel = (isOpen = true) => {
	const hamburgerButton = document.querySelector('button.c-nav-mobile');
	if (!hamburgerButton) return;

	const ariaLabel = isOpen ? hamburgerButton.dataset['ariaCloseLabel'] : hamburgerButton.dataset['ariaOpenLabel'];

	hamburgerButton.setAttribute('aria-label', ariaLabel);
	hamburgerButton.setAttribute('aria-expanded', isOpen ? 'true' : 'false');
};

/**
 * Attaches toggle main-nav behavior to hamburger button
 * @listens button.c-nav-mobile~e:click
 * @triggered {@link initInlineStacking}
 */
export const attachMobileToggleBehaviour = () => {
	const toggle = document.querySelector(".c-nav-mobile");

	toggle.addEventListener('click', toggleNavMobile);
};

/**
 * Toggles main-nav via toggling the .nav-open class on the documents' root elem
 * @fires {@link adjustHamburgerButtonAriaLabel}
 * @triggered f:{@link attachMobileToggleBehaviour} -> .c-nav-mobile~e:click
 */
function toggleNavMobile() {
	const navOpened = document.documentElement.classList.toggle('nav-open');
	adjustHamburgerButtonAriaLabel(navOpened);

	if (navOpened) {
		// It should happen only once, on initial open
		if (allHeaderFocusableEls == null) {
			allHeaderFocusableEls = Array.from(
				header.querySelectorAll(
					`button:not([tabindex="-1"]):not([aria-hidden]),
					input:not([tabindex="-1"]):not([aria-hidden]),
					a:not([tabindex="-1"]):not([aria-hidden]),
					[tabindex="0"]`
				)
			).filter((el) => el.offsetParent != null);

			headerFirstFocusableEl = allHeaderFocusableEls[0];
			headerLastFocusableEl = allHeaderFocusableEls.at(-1);
		}

		header?.addEventListener('keydown', handleHeaderKeydown);
		headerFirstFocusableEl?.addEventListener('keydown', preventFocusGoingOutsideBackward);
	} else {
		header?.removeEventListener('keydown', handleHeaderKeydown);
		headerFirstFocusableEl?.removeEventListener('keydown', preventFocusGoingOutsideBackward);
	}
}

/**
 * Adds aria-labels for the main-menu dropdown-buttons & corresponding sub-menu containers
 * @triggered f:{@link attachObservers}
 */
export const attachAriaAttributes = () => {
	const subMenus = document.querySelectorAll(".c-nav-item--has-sub-menu > .c-nav__submenu-container");
	subMenus.forEach(subMenu => {
		// Set aria attribute for sub-menu
		subMenu.setAttribute("aria-hidden", "true");
	});
	const dropdownButtons = document.querySelectorAll(".c-nav-item__button");
	dropdownButtons.forEach(button => {
		// Set aria attributes for sub-menu button
		button.setAttribute("aria-haspopup", "true");
		button.setAttribute("aria-expanded", "false");
	});
};

/**
 * Checks if the "next active element" is inside the header, and in case it isn't,
 * it brings it back to the first focusable element of the header
 * @triggered f:{@link toggleNavMobile} -> header.c-nav~e:keydown
 */
function trapFocusInsideHeader() {
	setTimeout(() => {
		if (!header.contains(document.activeElement)) {
			headerFirstFocusableEl.focus();
		}
	}, 0);
}

/**
 * If pressed key is "Escape", it closes the main nav
 * @triggered f:{@link handleHeaderKeydown} - handler for the keydown event, which is attached to the opened header
 */
function attachEscClose(e) {
	if (e.key == 'Escape') {
		toggleNavMobile();
	}
}

/**
 * When "navigating backwards" with Tab + Shift key, prevent focus to go outside the header
 * @triggered f:{@link toggleNavMobile} -> header <first-focusable-element>~e:keydown
 */
function preventFocusGoingOutsideBackward(e) {
	if (e.key == 'Tab' && e.shiftKey) {
		e.preventDefault();
		headerLastFocusableEl.focus();
	}
}

function handleHeaderKeydown(e) {
	trapFocusInsideHeader();
	attachEscClose(e);
}

export default initInlineStacking;
