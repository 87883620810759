export default function initHeylo() {
    const form = document.querySelector('.ch__form');
    const fieldNames = ['no', 'cd', 'accd', 'acc'];

    if (form) {
        if (window.location.search.length > 4) {
            fieldNames.forEach(el => {
                const value = getQueryVar(el);
                if (value) {
                    const element = form.querySelector(`[name="${el}"]`);
                    if (!element) {
                        return
                    }
                    if (element.type == 'checkbox' && value == 'true') {
                        element.click();
                    } else {                    
                        form.querySelector(`[name="${el}"]`).value = value;
                    }
                }
            });
        }
    }
}

function getQueryVar(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    let toReturn = null;
    
    vars.forEach((el, i) => {
        const pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            toReturn = decodeURIComponent(pair[1]);
        }
    });
    return toReturn;
}