export default function initTabbingCarousel(element) {

	const tabs = element.querySelectorAll('[data-tab]');
	const content = element.getElementsByClassName('active');

	const toggleContent = function() {
		if (!this.classList.contains("active")) {

			Array.from(content).forEach( item => {
				item.classList.remove('active');
				item.setAttribute('aria-hidden', 'true');
			});

			this.classList.add('active');

			let currentTab = this.getAttribute('data-tab'),
				tabContent = element.querySelector(`#${currentTab}`);

			tabContent.classList.add('active');
			tabContent.removeAttribute('aria-hidden');
		}
	};

	Array.from(tabs).forEach( item => {
		item.addEventListener('click', toggleContent);
	});
}
