<template>
    <component
		:is="tag"
		v-if="!basketHidden || basketHiddenWhenEmpty"
		:href="useFlyOutBasket ? null : url"
		@click="click"
		aria-labelledby="open-basket-label"
		itemprop="url"
		data-basket-nav-src="service menu basket"
		class="c-nav-basket__link"
		data-testid="basket-link"
		aria-haspopup="dialog"
		aria-controls="flyOutBasket"
		:aria-expanded="flyOutBasketActive"
	>
        <img v-if="iconUrl" :src="iconUrl" alt="" class="c-nav-basket__custom-icon" aria-hidden="true" />
		<DsIcon v-else icon-name="shopping-basket" aria-hidden="true" />
        <span itemprop="name" class="c-nav-basket__text" aria-hidden="true" id="open-basket-label">{{ openBasketLabel }}</span>
        <mini-basket-count v-if="count" :basket-count="count" aria-hidden="true"></mini-basket-count>
	</component>
</template>

<script>
import Events from 'shared/settings/events';
import { mapGetters } from 'vuex';
import { DsIcon } from "@coloplast/design-system";

export default {
    props: {
        basketUrl: {
            type: String,
            required: true,
        },
        basketLinkText: {
            type: String,
            required: true,
        },
        iconUrl: {
            type: String,
            required: false,
        },
        basketItemCount: {
            type: Number,
            required: false,
        },
        useFlyOutBasket: {
            type: Boolean,
            required: true,
        },
		basketHidden: {
			type: Boolean,
			required: false,
		},
		openBasketLabelTemplate: {
			type: String,
			default: "Open basket",
		},
    },
	components: {
		DsIcon,
	},
    computed: {
        url() {
            if (this.useFlyOutBasket) {
                return '#';
            }
            else {
                return this.basketUrl;
            }
        },
        ...mapGetters({
            numberOfBasketItems: 'getBasketItemCount',
			flyOutBasketActive: 'getFlyOutBasketActive',
        }),
        count() {
            if (this.numberOfBasketItems == 0) {
                return this.basketItemCount;
            }
            else {
                return this.numberOfBasketItems;
            }
        },
		basketHiddenWhenEmpty() {
			return this.basketHidden && this?.count > 0;
		},
		openBasketLabel() {
			return this.openBasketLabelTemplate?.replace('$items$', this.numberOfBasketItems) ?? this.basketLinkText;
		},
		tag() {
			return this.useFlyOutBasket ? 'button' : 'a';
		},
    },
    methods: {
        click(e) {
            if (this.useFlyOutBasket) {
                e.preventDefault();
                const event = new CustomEvent(Events.ACTIVATE_FLY_OUT_BASKET, {});
                document.dispatchEvent(event);
            }
        }
    }
}
</script>
