<template></template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Cookie from 'js-cookie';
import { cookieNames } from 'shared/settings/constants';
import { track_begin_checkout } from 'shared/services/tracking/basketTrackingService';

export default {
	computed: {
		...mapGetters({
			basket: 'getBasket',
		}),
	},
	methods: {
		...mapActions({
			fetchBasket: 'fetchBasket',
		}),
	},
	async mounted() {
		const quickSampleInitialized = Cookie.get(cookieNames.QUICK_SAMPLE_INITIALIZED);

		if (quickSampleInitialized === 'True') {
			await this.fetchBasket();

			track_begin_checkout(this.basket, { quick_link: 'yes' });

			Cookie.remove(cookieNames.QUICK_SAMPLE_INITIALIZED);
		}
	},
};
</script>
