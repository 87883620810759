let rootElement = null;

function show(element) {
  element.classList.remove('js-hide');
}

function hide(element) {
  element.classList.add('js-hide');
}

function onChange(element) {
  const showClass = element.target.dataset.showClass;
  const hideClass = element.target.dataset.hideClass;
  const elementsToShow = [...rootElement.querySelectorAll(`.${showClass}`)];
  elementsToShow.forEach((elm) => {
	show(elm);
  });
  const elementsToHide = [...rootElement.querySelectorAll(`.${hideClass}`)];
  elementsToHide.forEach((elm) => {
	hide(elm);
  });
}

function onConsentChange(element) {
  const selected = document.querySelector('[name=ConsentChoiceId]:checked');
  const accepted = document.getElementById('consent-given');
  if (!accepted) {
	return;
  }
  if (selected && selected.value) {
	accepted.value = 'True';
  } else {
	accepted.value = 'False';
  }
}

function resetConsent() {
	var choice = document.querySelector('[name=ConsentChoiceId]:checked');
	if (choice) {
		choice.checked = false;
	}
	var accepted = document.getElementById('consent-given');
	if (accepted) {
		accepted.value = 'False'
	}
}

export default function (root) {
	rootElement = root || document.documentElement;

	const userTypeOptions = [...document.querySelectorAll('.usertype-selector')];
	userTypeOptions.forEach((userTypeOption) => {
		userTypeOption.addEventListener('change', resetConsent);
	})

	const activators = [...root.querySelectorAll('.js-showhide-activator')];
	activators.forEach((activator) => {
		activator.addEventListener('change', onChange);
		if (activator.checked) {
			onChange({ target: activator });
		}
	});

	// setup multiple consent handlers
	const consentOptions = [...document.querySelectorAll('[name=ConsentChoiceId]')];
	consentOptions.forEach((consentOption) => {
		consentOption.addEventListener('change', onConsentChange);
	})

	// setup the remove from basket click handlers
	const buttons = [...document.querySelectorAll('[data-js*="removeFromBasket"]')];
	buttons.forEach(button => {
		button.addEventListener('click', async (event) => {
			const apiUrl = '/api/global-basket/';

			try {
				await axios.get(`${apiUrl}basket?language=${window.data.Culture}`);
			} catch (error) {
				console.error(error);
			}
		});
	})

}
