<template>
	<time :datetime="datetime" :lang="lang">{{ formattedDate }}</time>
</template>
<script>
import { formatShortDate, formatLongDate, formatTime } from "../vanilla/date";

/**
 * Expose a `<date-time>` component that can be used by the Backend to render out a clientside formatted time element, localised to the current page locale.
 * @example
 * Input:
 * <date-time datetime="2021-11-11T11:21:39.180Z" format="long />
 *
 * Output:
 * <time datetime="2021-11-11T11:21:39.180Z">Thursday, 11. november, 2021</time>
 **/
export default {
	name: "date-time",
	props: {
		lang: String,
		datetime: {
			type: [Date, String, Number],
			required: true,
		},
		format: {
			type: String,
			validator: (prop) =>
				["short-date", "short", "long-date", "long", "time"].includes(
					prop
				),
		},
	},
	computed: {
		formattedDate() {
			const tz = process.env.NODE_ENV === 'test' ? 'UTC' : undefined
			switch (this.format) {
				case "time":
					return formatTime(this.datetime, this.lang, tz);
				case "long-date":
				case "long":
					return formatLongDate(this.datetime, this.lang, tz);
				case "short-date":
				case "short":
				default:
					return formatShortDate(this.datetime, this.lang, tz);
			}
		},
	},
};
</script>
