import Cookies from 'js-cookie';

let bannerHeights = [];
const banners = document.querySelectorAll('.c-banner--footer-sticky-banner');
const activeClass = 'initted';
const bannerDummyClass = 'c-banner--footer-sticky-banner-dummy';
const closeBannerClass = 'c-banner--footer-sticky-banner-close';
const ie11BannerClass = 'c-banner--ie11-banner';
let ie11BannerAllowed = false;
let totalHeight = 0;
let dummy;
const date = new Date();
const interval = 43200000; // 12 hours in milliseconds are 43200000

const detectIE = function detectIE(windowObj) {
	const ua = windowObj.navigator.userAgent;
	const msie = ua.indexOf('MSIE ');
	const trident = ua.indexOf('Trident/');
	if (msie > 0) { // IE 10 or older => return version number
		return !!(parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10));
	}
	if (trident > 0) { // IE 11 => return version number
		const rv = ua.indexOf('rv:');
		return !!(parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10));
	}
	return false;
};

const cleanBannerHeights = function cleanBannerHeights() {
	ieNotification.bannerHeights = [];
};

const setBannerHeights = function setBannerHeights() {
	ieNotification.totalHeight = 0;
	ieNotification.banners.forEach(banner => {
		const bannerHeight = banner.getBoundingClientRect().height;
		ieNotification.bannerHeights.push(bannerHeight);
		ieNotification.totalHeight += bannerHeight;
	});
	ieNotification.totalHeight = ieNotification.totalHeight === 0 ? ieNotification.totalHeight : ieNotification.totalHeight - 1;
};

const setBannerPositions = function setBannerPositions() {
	ieNotification.banners.forEach((banner, i) => {
		const offset = ieNotification.bannerHeights.slice(0, i).reduce((acc, val) => acc + val, 0);
		banner.style.bottom = `${offset}px`;
	});
};

const setBanners = function setBanners() {
	ieNotification.cleanBannerHeights();
	ieNotification.setBannerHeights();
	ieNotification.setBannerPositions();
	if (ieNotification.dummy) {
		ieNotification.dummy.style.height = `${ieNotification.totalHeight}px`;
	}
};

const handleClick = function handleClick(event) {
	if (event.target.classList[0] === ieNotification.closeBannerClass) {
		event.stopPropagation();
		event.preventDefault();

		const node = event.target.parentNode.parentNode;
		const isIe11class = ([...event.target.parentNode.classList]).some(item => item === ieNotification.ie11BannerClass);
		if (node.parentNode) {
			node.parentNode.removeChild(node);
		}
		ieNotification.setBanners();

		if (isIe11class) {
			Cookies.set(ieNotification.ie11BannerClass, (new Date()).getTime());
			sessionStorage.setItem(ieNotification.ie11BannerClass, true);
		}
	}
};

const getAccess = function getAccess() {
	const sessionDate = ieNotification.date.getTime();
	const cookieValue = Number(Cookies.get(ieNotification.ie11BannerClass)) || 0;
	const cookieAllow = cookieValue + ieNotification.interval < sessionDate;
	const sessionHasStarted = sessionStorage.getItem(ieNotification.ie11BannerClass) === 'true';
	ieNotification.ie11BannerAllowed = cookieAllow && !sessionHasStarted;
	return ieNotification.detectIE(window) && ieNotification.ie11BannerAllowed;
};

const init = function init() {
	if (ieNotification.getAccess()) {
		ieNotification.dummy = document.querySelector(`.${ieNotification.bannerDummyClass}`);
		if (!ieNotification.dummy) {
			ieNotification.dummy = document.createElement('div');
			ieNotification.dummy.classList.add(`.${ieNotification.bannerDummyClass}`);
			document.body.appendChild(ieNotification.dummy);
		}

		ieNotification.cleanBannerHeights();
		ieNotification.banners.forEach(banner => {
			banner.classList.add(ieNotification.activeClass);
		});
		ieNotification.setBannerHeights();
		ieNotification.setBannerPositions();
		if (ieNotification.dummy) {
			ieNotification.dummy.style.height = `${ieNotification.totalHeight}px`;
		}

		window.addEventListener('resize', function (event) {
			ieNotification.setBanners();
		});

		window.addEventListener('click', function (event) {
			ieNotification.handleClick(event);
		});
	}
}

const ieNotification = {
	banners,
	activeClass,
	bannerHeights,
	bannerDummyClass,
	closeBannerClass,
	ie11BannerClass,
	ie11BannerAllowed,
	totalHeight,
	dummy,
	date,
	interval,
	detectIE,
	cleanBannerHeights,
	setBannerHeights,
	setBannerPositions,
	setBanners,
	init,
	getAccess,
	handleClick
};

export default ieNotification;
