export default class RemoveOutline {

    constructor() {
        const _this = this;
        document.body.addEventListener("mousedown", (event) => {_this.mouseEvent(event);});
        document.body.addEventListener("touchstart", (event) => {_this.mouseEvent(event);});
        document.body.addEventListener("keydown", () => { _this.clearOnKeyDown();});
    }

    mouseEvent() {
        document.body.classList.add("remove-outline");
    }

    clearOnKeyDown() {
        document.body.classList.remove("remove-outline");
    }
}