import { initInlineStacking } from "./inlineStackingMenu";
import { initStickyMenu } from "./stickyMenu";

export const initNavDomReady = (menuElem) => {
	if (menuElem) {
		if (menuElem.dataset.sticky === "true") {
			initStickyMenu(menuElem);
		}
		initInlineStacking();
	}
};

export const initNav = (fn = initNavDomReady) => {
	// If the header is already present then execute the code immediately, else we'll wait until the DOM is fully loaded
	const headerElem = document.querySelector("header.c-nav");
	const menuElem = document.querySelector(".c-nav-menu");
	if (headerElem && menuElem) {
		fn(menuElem);
	} else {
		window.addEventListener("DOMContentLoaded", () => fn(menuElem));
	}
};

export default initNav;
