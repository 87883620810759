<template></template>

<script>
	import { mapGetters, mapMutations } from 'vuex';
	import Cookie from 'js-cookie';
	import { removeQueryStringParameter, extractUrlParameters } from 'shared/services/windowLocationService';
	import { track_login, track_login_start, track_sign_up_start, track_sign_up } from 'shared/services/tracking/loginTrackingService.ts';
	import axios from 'axios';

	const LOGGED_IN = 'loggedin';
	const PROFILE_CREATED = 'profilecreated';
	const SIGNUP_SUCCESS = 'signupsuccess';

	export default {
		name: 'login-tracker',
		computed: {
			...mapGetters({
				visitorID: 'getVisitorID',
				isUserLoggedIn: 'getIsUserLoggedIn',
				isHcpUser: 'getIsHcpUser',
			})
		},
		data() {
			return {
				loginBtns: [],
				signUpBtns: [],
			}
		},
		mounted() {
			const searchParameters = extractUrlParameters(window.location.search);
			// first track any login and update the UserType cookie
			if (searchParameters.loggedin == 'true') {
				if (this.isHcpUser) {
					this.getHCPProfile();
				}
				else {
					track_login();
					this.setCookies('Login');
				}
				removeQueryStringParameter(LOGGED_IN);
				this.setUserJustLoggedIn();
			}
			// then update the UserType cookie if a logout is detected
			else if (searchParameters.loggedin == 'false' || !Cookie.get('UserType') && !this.isUserLoggedIn) {
				this.setCookies('Anonymous');
				removeQueryStringParameter(LOGGED_IN);
			}

			// now check if the user just signed up
			// if both the profilecreated and signupsuccess parameters are present they need to both be 'true'
			if (searchParameters.hasOwnProperty(PROFILE_CREATED) && searchParameters.profilecreated == 'true' && searchParameters.signupsuccess == 'true') {
				track_sign_up();
				removeQueryStringParameter(PROFILE_CREATED);
				removeQueryStringParameter(SIGNUP_SUCCESS);
			}
			// else if the profilecreated paraemeter is not there then we only need signupsuccess to be 'true'
			else if (!searchParameters.hasOwnProperty(PROFILE_CREATED) && searchParameters.signupsuccess == 'true') {
				track_sign_up();
				removeQueryStringParameter(SIGNUP_SUCCESS);
			}

			// now clean up the profilecreated and signupsuccess query string parameters
			if (searchParameters.hasOwnProperty(PROFILE_CREATED)) {
				removeQueryStringParameter(PROFILE_CREATED);
			}
			if (searchParameters.hasOwnProperty(SIGNUP_SUCCESS)) {
				removeQueryStringParameter(SIGNUP_SUCCESS);
			}

			// now attach tracking event handlers to login and sign up buttons
			this.loginBtns = [...document.querySelectorAll('[data-flow-name="SignIn"]')];
			this.loginBtns.forEach((btn) => {
				btn.addEventListener('click', this.trackLogin);
			});
			this.signUpBtns = [...document.querySelectorAll('[data-flow-name="SignUp"]')];
			this.signUpBtns.forEach((btn) => {
				btn.addEventListener('click', this.trackSignUp);
			});
		},
		beforeUnmount() {
			this.loginBtns.forEach((btn) => {
				btn.removeEventListener('click', this.trackLogin);
			});
			this.signUpBtns.forEach((btn) => {
				btn.removeEventListener('click', this.trackSignUp);
			});

		},
		methods: {
			...mapMutations([
				'setUserJustLoggedIn',
			]),
			setCookies(userStatus) {
				const domain = new URL(window.location.href).hostname;
				Cookie.set('UserType', window.btoa(`${userStatus}`), { domain: domain, secure: true, sameSite: 'None' });
			},

			getHCPProfile() {
				const
					getProfileUrl = '/api/myhcpprofile/profile',
					setFirstTimeLogin = '/api/HcpSignUp/firsttimelogindone';
				axios.get(getProfileUrl)
					.then(response => {
						if (!response.data.firstTimeLoginDone) {
							axios.post(setFirstTimeLogin)
								.catch(error => {
									console.warn(error);
								});
						}
					})
					.catch(error => {
						console.warn(error);
					})
			},

			trackLogin() {
				track_login_start();
			},
			trackSignUp() {
				track_sign_up_start();
			},
		}
	}

</script>
