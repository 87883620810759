<template></template>

<script>
	export default {
		name: "product-animations",
		data: () => {
			return {
				intersectionObserver: null,
				main: null,
				oneblocksAll: null,
				oneblocks: null,
				tryCount: 0,
			};
		},
		methods: {
			// OneBlocks have to load before we can target them with animations
			initAfterOneBlocksHaveLoaded() {
				this.oneblocksAll = document.querySelectorAll(".c-ob");

				// A OneBlock is considered loaded when the preset has been removed and the dynamic classes set
				const oneblocksToLoad = [...this.oneblocksAll].filter(ob => ob.getAttribute("data-ob-ps") && ob.classList.length === 1);
				if (this.tryCount >= 30) {
					// If OneBlocks haven't loaded after a number of attempts stop trying to attach animations to them
					delete this.main.dataset.settingUpAnimations;
				}
				else if (oneblocksToLoad.length) {
					this.tryCount++;
					setTimeout(this.initAfterOneBlocksHaveLoaded, 100);
				} else {
					this.init();
				}
			},
			init() {
				const spots = document.querySelectorAll(".c-section--w-33 .c-section__item > .c-ob");
				spots.forEach(spot => {
					spot.classList.add("c-ob--spot");
				});
				this.oneblocks = document.querySelectorAll(".c-ob.-O-ly1, .c-ob.-O-ly9, .c-ob.-O-ly13, .c-ob.-O-ly20, .c-ob.-O-ly22, .c-ob.-O-ly23, .c-ob--spot, .c-ob:not([class*='-O-ly'])");

				this.oneblocks.forEach(ob => {
					ob.dataset.readyToAnimate = true;
					ob.dataset.hasVideo = this.setVideoBehaviour(ob);
					this.intersectionObserver.observe(ob);
				});

				delete this.main.dataset.settingUpAnimations;
			},
			setVideoBehaviour(ob) {
				const video = ob.querySelector("video");
				if (video) {
					video.autoplay = false;
					video.loop = false;
					video.addEventListener('loadedmetadata', function(e) {
						e.target.currentTime = 0;
						e.target.pause();
					});
					video.pause();
					return true;
				}
				return false;
			},
			observe(entries) {
				const intersectingEntries = entries.filter(x => x.isIntersecting);
				intersectingEntries.forEach(entry => {
					entry.target.dataset.triggerAnimation = true;
					delete entry.target.dataset.readyToAnimate;
					if (entry.target.dataset.hasVideo === "true") {
						entry.target.querySelector("video").play();
					}
				});

				const notIntersectingEntries = entries.filter(x => !x.isIntersecting);
				notIntersectingEntries.forEach(entry => {
					if (entry.target.dataset.hasVideo === "true") {
						entry.target.querySelector("video").pause();
					}
				});
			},
		},
		mounted() {
			const hasIntersectionObserverSupport = typeof IntersectionObserver === "function";
			const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
			const shouldAttachAnimations = hasIntersectionObserverSupport && !isIE11;

			this.main = document.querySelector("main");

			if (this.main && shouldAttachAnimations) {
				this.main.dataset.settingUpAnimations = true;
				this.main.dataset.animationPage = true;
				this.intersectionObserver = new IntersectionObserver(this.observe, { threshold: .5 });
				this.initAfterOneBlocksHaveLoaded();
			}
		},
	}
</script>
