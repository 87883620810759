<template>
	<genericModal
		:modal-data="modalData"
		@close-modal="emits('close')">
		<template v-slot:header>
			<DsText
				:id="formatDialogId(modalData.dataName, 'label')"
				class="c-variant-review-quantity-modal__heading"
				html-element="h2"
				text-type="heading-2xl"
				text-style="bold"
				data-testid="prescription-modal--heading"
			>{{ modalLabels.consentHeadlineText }}</DsText>
		</template>

		<template v-slot:body>
			<DsText
				html-element="div"
				text-type="body-lg">
				<div
					:id="formatDialogId(modalData.dataName, 'desc')"
					class="c-variant-review-quantity-modal__body"
					data-testid="prescription-modal--intro"
					v-html="modalLabels.consentIntroText" />
			</DsText>
			<div class="c-variant-review-quantity-modal__checkbox">
				<input
					type="checkbox"
					id="confirmPrescription"
					name="confirmPrescription"
					v-model="consentsState.prescriptionConsentAccepted"
				/>
				<DsText
					html-element="label"
					data-testid="prescription-modal--consent-checkbox"
					for="confirmPrescription">{{ modalLabels.consentCheckBoxText }}</DsText>
			</div>
		</template>

		<template v-slot:footer>
			<DsButtonGroup>
				<DsButton
					@click.prevent="emits('cancel')"
					type="button"
					data-testid="prescription-modal--cancel"
					variant="ghost">{{ modalLabels.consentCancelButtonText }}</DsButton>
				<DsButton
					:aria-label="setFormatAriaLabel('modalLabels.consentSubmitButtonText')"
					:disabled="!consentsState.prescriptionConsentAccepted"
					type="button"
					variant="primary"
					@click.prevent="emits('continue')"
					data-testid="prescription-modal--submit"
					size="md">{{ modalLabels.consentSubmitButtonText }}</DsButton>
			</DsButtonGroup>
		</template>
	</genericModal>
</template>

<script lang="ts" setup>
import { DsButton, DsButtonGroup, DsText } from '@coloplast/design-system';
import { formatAriaLabel, formatDialogId } from "../../../shared/services/ariaReferenceService";
import genericModal from '../../../shared/components/generic-modal/genericModal.vue';

defineProps(['modalData', 'modalLabels', 'consentsState']);
const emits = defineEmits(['close', 'cancel', 'continue']);

const setFormatAriaLabel = (str = 'submit') => {
	return formatAriaLabel(str);
}

</script>
