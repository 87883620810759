import axios from 'shared/tools/axiosMultisite';

const GET_CONSENT_CONFIRMATION_API = '/api/sample-consent/consent';
const POST_CONSENT_CONFIRMATION_API = '/api/sample-consent/add-consent';

export const checkConsentConfirmation = async function checkConsentConfirmation(params) {
	const {SampleConsentType, Language, Code} = params;
	try {
		return await axios.get(GET_CONSENT_CONFIRMATION_API, {
			params: {
				SampleConsentType,
				Language,
				Code
			}
		})
	} catch (err) {
		console.error(err);
	}
}

export const updateConsentConfirmation = async function updateConsentConfirmation(data) {
	try {
		return await axios.post(POST_CONSENT_CONFIRMATION_API, data)
	} catch (err) {
		console.error(err);
	}
}

const ConsentConfirmationService = {
	updateConsentConfirmation,
	checkConsentConfirmation,
};

export default ConsentConfirmationService;
