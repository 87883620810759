<template>
	<generic-modal
		v-if="showModal"
		:modal-data="model"
		@close-modal="hideGlobalLoginExpiredModal">

		<template v-slot:header>
			<h2 class="c-enrollment-modal__headline" >{{ model.headline }}</h2>
		</template>

		<template v-slot:body>
			<div class="c-enrollment-modal__description" v-html="model.description"></div>
		</template>

		<template v-slot:footer>
			<a class="e-button e-button--filled e-button--width-dynamic"
				:href="model.buttonUrl"
				tabindex="0"
				data-testid="login"
				>
				{{ model.buttonText }}
			</a>
		</template>
	</generic-modal>
</template>

<script>
import genericModal from 'shared/components/generic-modal/genericModal.vue';

import { mapGetters, mapActions } from 'vuex';

export default {
    name: "login-expired-modal",

    props: {
        vm: {
            type: Object,
			required: true,
			default: () => {}
        },
		trigger: {
			type: String,
			required: true,
       },
    },

	mounted() {
		if ( this.vm.BaseViewModel.IsEnabled ) {
			this.enableGlobalLoginExpiredModal();

			document.addEventListener(this.trigger, (e) => {
				this.showGlobalLoginExpiredModal();

			})
		}
	},

	computed: {
		...mapGetters({
			showModal: 'getGlobalLoginExpiredModalIsShown',
		}),

		model() {
			return {
				dataName: 'expired-session',
				headline: this.vm.BaseViewModel.HeadlineText,
				description: this.vm.BaseViewModel.Description,
				buttonText: this.vm.BaseViewModel.LoginButtonText,
				buttonUrl: this.vm.BaseViewModel.LoginButtonUrl
			}
		}
	},

	methods: {
		...mapActions([
			'showGlobalLoginExpiredModal',
			'hideGlobalLoginExpiredModal',
			'enableGlobalLoginExpiredModal',
			'disableGlobalLoginExpiredModal'
		]),
	},

	components: {
		'generic-modal': genericModal,
	}
}
</script>

<style scoped lang="scss">
.c-variant-expired-session-modal {
	&.c-modal__mask--grey {
		background: rgba(0, 0, 0, 0.2);
	}

	:deep {
		.c-modal__wrapper--expired-session {
			padding-bottom: 32px;
		}
		.c-variant-expired-session-modal__modal-container {
			max-width: inherit !important;
		}

		.c-enrollment-modal__description {
			margin-bottom: 48px;
		}
	}
}

</style>
