<template>
	<product-animations v-if="type === 'Contend'"></product-animations>
</template>

<script>
import ProductAnimations from "contend/components/productAnimations.vue";

export default {
	name: "animationProvider",
	components: {
		"product-animations": ProductAnimations
	},
	props: {
		type: String
	}
}
</script>
