let rootElement = null;
let bodyElement = null;
const HIDE_CLASS = 'js-hide';
const IOS_MODAL_MODIFIER = '-ios';
const IOS_MODAL_BODY_MODIFIER = '-ios-modal';

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

function show() {
    rootElement.classList.remove(HIDE_CLASS);
    if (iOS) {
        rootElement.classList.add(IOS_MODAL_MODIFIER);
        bodyElement.classList.add(IOS_MODAL_BODY_MODIFIER);
    }
    else {
        const dialog = rootElement.querySelector('[data-elm="dialog"]');
        const boundingBox = dialog.getBoundingClientRect();
        dialog.style['min-width'] = `${boundingBox.width}px`; 
    }
}

function close(e) {
    if (e) {
        e.preventDefault();
    }
    rootElement.classList.add(HIDE_CLASS);
    if (iOS) {
        rootElement.classList.remove(IOS_MODAL_MODIFIER);
        bodyElement.classList.remove(IOS_MODAL_BODY_MODIFIER);
    }
}

export default function initModalDialog(element) {
    rootElement = element;
    bodyElement = document.body;
    const trigger = rootElement.dataset.trigger;
    if (!trigger) {
        return;
    }
    document.addEventListener(trigger, show);

    const closeBtn = rootElement.querySelector('[data-js="close"]')
    if (closeBtn) {
        closeBtn.addEventListener('click', close);
    }
}